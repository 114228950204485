import React from 'react';

import Layout from '../components/Layout';

import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';

const IndexPage = () => (
  <Layout>
    <Header textClass="text-black" />

    <header className="masthead">
      <div className="">
        <div className="row banner no-gutters d-flex h-100 align-items-center">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <img
              src="/all/television-copy.png"
              srcSet="/all/television-copy@2x.png, /all/television-copy@3x.png"
              alt="People Are Awesome"
            ></img>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 d-flex flex-column h-100 banneer-right-side">
            <h2 className="Digital-Linear-Chann">
              Digital Linear Channels <br></br> & Video on Demand
            </h2>
            <h3 className="Our-brands-are-every">
              Our brands are everywhere CTV viewing takes place
            </h3>
            <button className="btn contact-us">
              Contact Us
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
    <section className="available text-center">
      <h6 className="Available-today-on ">Available today on:</h6>
      <div className="d-flex align-items-center justify-content-center flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
        <img
          src="/all/samsung.png"
          srcSet="all/samsung@2x.png, all/samsung@3x.png"
          alt="Samsung_TV_plus"
          className="samsung"
        ></img>
        <img
          src="/all/pluto-tv-logo.png"
          srcSet="all/pluto-tv-logo@2x.png 2x,
          all/pluto-tv-logo@3x.png 3x"
          alt="Pluto_TV"
          className="Pluto"
        ></img>
        <img
          src="/all/roku-logo.png"
          srcSet="all/roku-logo@2x.png,
          all/roku-logo@3x.png"
          alt="Roku_logo"
          className="roku-logo"
        ></img>
        <img
          src="/all/pngix-com-fire-logo-png-632324.png"
          srcSet="all/pngix-com-fire-logo-png-632324@2x.png,
             all/pngix-com-fire-logo-png-632324@3x.png"
          className="pngix"
          alt="fire_logo"
        ></img>
      </div>
    </section>
    <section className="offering-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-4">
            <h1 className="Minute-Avg-Watch">
              <span>54-Minute</span>
              <br className="break"></br> Avg Watch Time
            </h1>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <h1 className="Million-Minutes">
              <span>300 Million</span>
              <br className="break"></br> Minutes Viewed /Month
            </h1>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4">
            <h1 className="Brand-Safe">
              <span>100%</span>
              <br className="break"></br> Brand Safe
            </h1>
          </div>
        </div>
        <div className="offer-type">
          <h6 className="text-center">Our Offering</h6>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="premium">
                <img
                  src="/all/premium.png"
                  srcSet="/all/premium@2x.png, /all/premium@3x.png"
                  className="premium-image"
                  alt="premium mid role"
                ></img>
                <h4 className="text-white">
                  <b>Premium Mid-Roll</b>
                </h4>
                <p className="text-white">
                  Includes sponsorships of original shows and linear programming
                  blocks -- 96% VTR benchmark and 100% viewability.
                </p>
                <hr className="white-border"></hr>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="premium">
                <img
                  src="/all/stream.png"
                  srcSet="/all/stream@2x.png, /all/stream@3x.png"
                  className="stream"
                  alt="in-stream content"
                ></img>
                <h4 className="text-white">
                  <b>In-Stream Content & Custom Creative</b>
                </h4>
                <p className="text-white branded-content">
                  Branded content ad pods up to three minutes in length.
                </p>
                <hr className="white-border"></hr>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="premium">
                <img
                  src="/all/rich-media.png"
                  srcSet="/all/rich-media@2x.png, /all/rich-media@3x.png"
                  className="rich-media"
                  alt="rich-media"
                ></img>
                <h4 className="text-white">
                  <b>Rich Media</b>
                </h4>
                <p className="text-white intractive-content">
                  Interactive rich display overlays and dynamic video
                  billboards. Clickable units render full screen.
                </p>
                <hr className="white-border"></hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="get-in-touch d-flex flex-column h-100 align-items-center">
      <div className="container">
        <div className="row ">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h6>
              <b>Get in Touch</b>
            </h6>
            <p>
              consectetur adipiscing elit. Integer bibendum ut ligula vel
              lacinia. Donec blandit tincidunt felis
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 d-flex alin-items-center">
            <a href="/contact" className="btn contact-us">
              Contact Us
              <i className="fa fa-chevron-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>
    <SocialLinks />
    <Footer />
  </Layout>
);

export default IndexPage;
